/*---------------------------------------
			TABLE OF CONTENTS
-----------------------------------------

	1. Settings
		a. Imports
		b. Variables
		c. Mixins
	2. Global
		a. Typography
		b. Form
		c. Buttons
		d. Lists
		e. Toggle Section
	3. Layout
	4. Components
		a. Layout Components
		_. Header
		_. Hero
		_. Panel

---------------------------------------*/

/*-----------------------
	1. SETTINGS
-----------------------*/

// a. Imports
@import url('https://fonts.googleapis.com/css?family=Overpass');

// b. Variables
$blue: #104d6b;
$orange: #f04f23;

$aluminum: #e1e1e1;
$pewter: #636468;
$sterling: #a8a9ad;

// c. Mixins
@mixin border-radius() {
	-webkit-border-radius: 3px;
	border-radius: 3px;
}

/*-----------------------
	2. GLOBAL
-----------------------*/
// a. Typography
body {
	font-family: "Overpass", Helvetica, Arial, sans-serif;
}
@for $i from 1 through 6 {
	h#{$i} {
	}
}
a {
	color: $blue;
}
.text-right {
	text-align: right;
}

// b. Form
label {
	font-size: 0.75rem;
	font-weight: bold;
}
input[type="text"] {
	font-size: 0.875rem;
	margin-bottom: 1rem;
	padding: 0.25rem;
	width: 100%;
}

// c. Buttons
button, .button, input[type="submit"] {
	background-color: $orange;
	border: 0;
	color: $white;
	display: inline-block;
	font-weight: bold;
	padding: 1rem 2rem;
	@include border-radius;
}

// d. Lists
.no-bull {
	list-style: none;
	padding-left: 0;
}
.dropdown-list {
	list-style: none;
	padding-left: 0;
	> li {
		&:before {
			color: $orange;
			content: '\f0da';
			font-family: fontawesome;
			margin-right: 0.25rem;
		}
		span {
			font-size: 0.875rem;
			font-weight: bold;
			margin-bottom: 0.25rem;
		}
	}
}

/*-----------------------
	4. COMPONENTS
-----------------------*/
// _. Heading
header {
	border-bottom: 23px solid $orange;
	padding-top: 1.5rem;
	padding-bottom: 1rem;
	.logo {
		float: right;
	}
}

// _. Panel
.panel {
	background: $sterling;
	margin-top: 2rem;
	margin-bottom: 2.5rem;
	padding: 0.5rem 1rem 1rem;
	@include border-radius;
	h1 {
		color: $orange;
		font-size: 1.5rem;
		margin-top: 0;
		margin-bottom: 0.5rem;
		text-align: center;
	}
	.builder-sign-in {
		display: block;
		margin-top: 1rem;
	}
}
// _. Headline
.headline {
	background: $pewter;
	color: $white;
	padding: 0.5rem;
	text-align: center;
	h2 {
		line-height: 1.2;
		margin: 0;
	}
	span {
		font-size: 0.875rem;
		font-style: italic;
	}
}
// _. Dropdown
.dropdown-pane {
	* {
		font-size: 0.875rem;
	}
}

// _. Image BG 
.image-bg {
	background: url(../img/8_17_TruckShare_24_7_PR_Release_4.jpg) no-repeat center center / cover;
}




.lineup {
	background: #ffffff;
	background: -moz-linear-gradient(left, #ffffff 0%, #a8a9ad 100%);
	background: -webkit-linear-gradient(left, #ffffff 0%,#a8a9ad 100%);
	background: linear-gradient(to right, #ffffff 0%,#a8a9ad 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#a8a9ad',GradientType=1 );
	text-align: center;
}
.content {
	padding-top: 2rem;
	padding-bottom: 2rem;
}
footer {
	font-size: 0.875rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	text-align: center;
}